<template>
    <mercur-card class="fill full-height-layout mb-3 mx-4">
            <h1 class="font-weight-light my-0">
                <span v-if="isEdit">Edit Commercial Product</span>
                <span v-else>Create Commercial Product</span>
            </h1>
            <div class="fill">
                <div class="row mt-3 col-12">
                    <div class="col-6">
                        <mercur-input :disabled="loading" v-model="$v.form.commercialProductName.$model" required  :class="{'form-invalid': $v.form.commercialProductName.$error}">
                            Commercial product name
                            <template #note>
                                <span class="form-error" v-if="!$v.form.commercialProductName.required">Commercial Product name is required</span>
                            </template>
                        </mercur-input>
                    </div>

                    <pretty-select
                        :disabled="loading"
                        class="productDropDown col-6"
                        placeholder="Product Template"
                        id="productConfigurationTemplate"
                        :multiple="false"
                        v-model="$v.form.productConfigurationTemplateId.$model"
                        v-on:keypress.enter.prevent
                        :options="productDropDown"
                        :reduce="product => product.productConfigurationId"
                        label="productConfigurationName"
                    ></pretty-select>
                    <template>
                                <span class="form-error" v-if="!$v.form.productConfigurationTemplateId.required">Product Template is required</span>
                    </template>
                </div>

                <mercur-checkbox :disabled="loading" class="ml-3 mb-3" v-model="$v.form.published.$model">Publish</mercur-checkbox>

                <div class="d-flex flex-wrap align-items-top">
                    <div class="col-7">
                        <template v-if="selectedTemplateInfo">
                            <mercur-item   v-for="(attributes, key) in listAttributes" :key="key" :expand="true">
                                <b>{{key}}</b>
                                <template slot="content">
                                    <div class="p-2 border-top bg-grey-lighter">
                                        <mercur-chip
                                            class="my-1"
                                            :class="form.attributeConfiguration[key] && form.attributeConfiguration[key].some(e => e.option === attribute.option) ? 'bg-yellow text-black' : ''"
                                            v-for="(attribute, index) in attributes"
                                            :key="index"
                                            @click="prepareExceptions(attribute,key)"
                                            :clickable="true">
                                            {{attribute.option}}
                                        </mercur-chip>
                                    </div>
                                </template>
                            </mercur-item>
                        </template>
                    </div>
                    <div class="col-5">
                        <div v-for="(keys, key) in Object.keys(form.attributeConfiguration)" :key="key">
                            <div class="d-flex flex-wrap align-items-top mt-3">
                                {{keys}} :
                                <div class="ml-2 mr-2" v-for="(val, keyval) in form.attributeConfiguration[keys]" :key="keyval">
                                    {{val.option}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-right">
                <mercur-button @click.native="submit" :disabled="$v.$invalid || loading" class="btn btn-yellow btn-raised text-uppercase">Submit</mercur-button>
            </div>
    </mercur-card>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import CONFIG from '@root/config'
import PrettySelect from '@/components/utils/PrettySelect'

export default {
    name: 'CommercialProductAddEdit',
    components: { PrettySelect },
    mixins: [ validationMixin ],
    data () {
        return {
            loading: false,
            detailsAux: {},
            productTemplates: [],
            productDropDown: [],
            selectedTemplateInfo: null,
            urlProducts: CONFIG.API.ROUTES.SUPPLIERS.PRODUCTS,
            form: {
                productConfigurationTemplateId: null,
                commercialProductName: null,
                attributeConfiguration: {},
                published: false,
            },
        }
    },
    validations: {
        form: {
            commercialProductName: { required },
            productConfigurationTemplateId: { required },
            published: { required },
        },
    },
    computed: {
        isEdit () {
            return this.$route.params.commercialProductId
        },
        listAttributes () {
            return this.selectedTemplateInfo.attributeConfiguration.selectedAttributes
        },
    },
    watch: {
        'form.productConfigurationTemplateId' () {
            if (this.form.productConfigurationTemplate === null) {
                this.selectedTemplateInfo = null
            } else {
                this.selectedTemplateInfo = this.productTemplates.find((w) => w.productConfigurationId === this.form.productConfigurationTemplateId || w.productConfigurationName === this.form.productConfigurationTemplateId)
            }
        },
    },
    methods: {
        submit () {
            this.$v.$touch()
            if (this.$v.$invalid) {
                return
            }
            let url = CONFIG.API.ROUTES.SUPPLIERS.COMMERCIAL_PRODUCTS.CREATE.replace('{supplierId}', this.supplierId)
            this.loading = true
            let payload = this.form

            if (this.isEdit) {
                payload.commercialProductId = this.$route.params.commercialProductId
                url = CONFIG.API.ROUTES.SUPPLIERS.COMMERCIAL_PRODUCTS.UPDATE.replace('{supplierId}', this.supplierId)
            } else {
                payload.productConfigurationTemplateId = this.selectedTemplateInfo.productConfigurationId
            }
            this.$api.post(url, payload).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: this.isEdit ? 'Commercial Product was updated' : 'Commercial Product was added',
                    type: 'success',
                })
                this.$router.push({
                    name: 'CommercialProductsView',
                    params: {
                        supplierId: this.supplierId,
                    },
                })
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        prepareExceptions (attribute, key) {
            if (this.form.attributeConfiguration[key]) {
                if (this.form.attributeConfiguration[key].some(existingAttribute => existingAttribute.attributeId === attribute.attributeId)) {
                    this.form.attributeConfiguration[key] = this.form.attributeConfiguration[key].filter(existingAttribute => existingAttribute.attributeId !== attribute.attributeId)
                    this.$set(this, 'form.attributeConfiguration', this.form.attributeConfiguration)
                } else {
                    this.form.attributeConfiguration[key].push(attribute)
                    this.$set(this, 'form.attributeConfiguration', this.form.attributeConfiguration)
                }
            } else {
                this.$set(this.form.attributeConfiguration, key, [attribute])
            }
            if (this.form.attributeConfiguration[key].length === 0) {
                delete this.form.attributeConfiguration[key]
                this.$set(this, 'form.attributeConfiguration', this.form.attributeConfiguration)
            }
        },
    },
    created () {
        if (this.isEdit) {
            this.loading = true
            this.$api.post(CONFIG.API.ROUTES.SUPPLIERS.COMMERCIAL_PRODUCTS.GETBYID.replace('{supplierId}', this.supplierId).replace('{commercialProductId}', this.$route.params.commercialProductId)).then(({ data }) => {
                this.detailsAux = data.data
                this.form.commercialProductName = data.data.commercialProductName
                this.form.published = data.data.published
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Getting commercial product templates failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
            })
        }

        this.loading = true
        this.$api.post(CONFIG.API.ROUTES.SUPPLIERS.PRODUCTS.replace('{supplierId}', this.supplierId), CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
            this.productTemplates = data.data.items
            this.productDropDown = this.productTemplates
            if (this.$route.params.productConfigurationId && !this.isEdit) {
                const template = this.productTemplates.find((w) => w.productConfigurationId === this.$route.params.productConfigurationId)
                this.form.productConfigurationTemplateId = template.productConfigurationName
            }

            if (this.isEdit) {
                const template = this.productTemplates.find((w) => w.productConfigurationId === this.detailsAux.productConfigurationTemplateId)
                this.form.productConfigurationTemplateId = template.productConfigurationName
                Object.keys(this.detailsAux.attributeConfiguration).forEach((key) => this.detailsAux.attributeConfiguration[key].forEach((prop) => this.prepareExceptions({ ...prop }, key)))
            }
        }).catch(data => {
            this.$root.$emit('notification:global', {
                message: `Getting product templates failed. Please try again.`,
                type: 'error',
                errors: data,
            })
        }).finally(() => {
            this.loading = false
        })
    },
}
</script>
